import { Fragment } from 'react'
import {
  Text,
  Input,
  Button,
  FormErrorMessage,
  Grid,
  FormControl,
  Checkbox,
  VStack,
  Link,
} from '@chakra-ui/react'
import { Link as NextLink } from '@/lib/Link'

const ConfirmForm = (props) => {
  const {
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    errorMsg,
    values,
    confirmed,
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <Grid gap="24px" p="55px 80px 0" mb="24px">
        {errorMsg && (
          <VStack spacing="4">
            <Text color="tomato">{errorMsg} </Text>
            {confirmed && (
              <NextLink passHref route="login">
                <Link
                  sx={{
                    color: 'gray.700',
                    fontSize: 'md',
                    textDecoration: 'underline',
                  }}
                >
                  กลับหน้าเข้าสู่ระบบ
                </Link>
              </NextLink>
            )}
          </VStack>
        )}

        {!confirmed && (
          <Fragment>
            <FormControl>
              <Input
                fontSize="sm"
                size="lg"
                placeholder="อีเมล"
                type="text"
                name="email"
                value={values.email}
                isDisabled
              />
            </FormControl>
            <FormControl isInvalid={errors.password && touched.password}>
              <Input
                fontSize="sm"
                size="lg"
                placeholder="รหัสผ่าน"
                type="password"
                name="password"
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.confirmPassword && touched.confirmPassword}
            >
              <Input
                fontSize="sm"
                size="lg"
                placeholder="ยืนยันรหัสผ่านอีกครั้ง"
                type="password"
                name="confirmPassword"
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={errors.marketingConsent && touched.marketingConsent}
            >
              <VStack>
                <Checkbox
                  name="marketingConsent"
                  onChange={handleChange}
                  colorScheme="primary"
                  alignItems="flex-start"
                  size="sm"
                  sx={{ textAlign: 'start' }}
                >
                  ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA Pro
                  และบริการอื่น ๆ จากเรา บริษัทในเครือ
                  บริษัทย่อยและพันธมิตรทางธุรกิจ &#8203;
                  <span style={{ whiteSpace: 'nowrap' }}>
                    (คุณสามารถยกเลิกได้ทุกเมื่อ)
                  </span>
                </Checkbox>
                <Text
                  sx={{
                    fontSize: 'xs',
                    color: 'customGray.700',
                    pl: 5,
                    textAlign: 'left',
                  }}
                >
                  PDPA Pro
                  อาจติดต่อคุณในบางโอกาสเพื่อให้ข้อมูลที่เป็นประโยชน์และเกี่ยวข้องกับสินค้าและบริการที่คุณเลือกใช้
                </Text>
              </VStack>
              <FormErrorMessage>{errors.marketingConsent}</FormErrorMessage>
            </FormControl>

            <Button
              colorScheme="primary"
              variant="solid"
              size="lg"
              type="submit"
              isLoading={isSubmitting}
            >
              บันทึกรหัสผ่านและเข้าสู่ระบบ
            </Button>
          </Fragment>
        )}
      </Grid>
    </form>
  )
}

export default ConfirmForm
