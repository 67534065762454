import React, { useState } from 'react'
import { get, startsWith } from 'lodash'
import { Grid, Box, Heading, Text, Link, useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Link as RouterLink } from '@/lib/Link'
import * as AuthServices from '@/lib/auth/AuthServices'
import * as PolicyServices from '@/features/dashboard/services'
import * as AuthHelpers from '@/lib/auth/helpers'

import LoginForm from '../LoginForm/LoginForm'

import getErrorMsg from '@/lib/helpers/getErrorMsg'
import { LoginFormInput } from '../../types'
import { loginFormSchema } from '../../schema'
import { gtmPush } from '@/lib/stats/gtm'

const RESENT_CONSTANT = 'เราได้ส่งข้อความและลิงก์การยืนยันตัวตนไปยังอีเมล'

type LoginLayoutProps = {
  handleSwitchModal?: (data: string) => void
  urlAfterLogin?: string
}

const LoginLayout = (props: LoginLayoutProps): React.ReactElement => {
  const { handleSwitchModal, urlAfterLogin = '/dashboard/policies' } = props

  const [loading, setLoading] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [showResentEmail, setShowResentEmail] = useState(false)

  const toast = useToast()
  const router = useRouter()
  const { query } = router

  const handleSubmit = async (values: LoginFormInput) => {
    const token = get(query, 'token')
    const redirectUrl = decodeURIComponent(
      (get(query, 'redirect_url') ?? urlAfterLogin) as string,
    )
    const { email, password } = values

    setLoading(true)

    try {
      const response = await AuthServices.login({ email, password, token })

      gtmPush({
        event: 'userData',
        user_id: response.data.user.id as string,
      })

      const headers = get(response, 'headers', {})

      AuthHelpers.setUserCookie({
        ac: get(headers, 'access-token'),
        client: get(headers, 'client'),
        uid: get(headers, 'uid'),
        expiry: get(headers, 'expiry'),
      })

      router.push(redirectUrl)
      window.scrollTo(0, 0)
    } catch (err) {
      const error = getErrorMsg(err)
      setErrorMsg(error)

      if (startsWith(error, RESENT_CONSTANT)) {
        setShowResentEmail(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const resendConfirmation = async () => {
    toast({
      title: 'Sending...',
      status: 'info',
      position: 'top-right',
    })
    try {
      await PolicyServices.resendConfirmation({ email })
      toast({
        title: 'Confirmation Email Sent.',
        status: 'success',
        position: 'top-right',
      })
    } catch (error) {
      toast({
        title: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  const method = useForm<LoginFormInput>({
    resolver: zodResolver(loginFormSchema),
  })

  const { watch } = method
  const email = watch('email')

  return (
    <FormProvider {...method}>
      <Grid textAlign="center" pb="32px">
        <Box
          p="36px 0 23px"
          borderBottom="1px solid"
          borderBottomColor="customGray.300"
        >
          <Heading fontSize="2xl" mb="8px">
            เข้าสู่ระบบ
          </Heading>
          <Text m="0 auto" fontSize="sm">
            หากยังไม่มีบัญชี&nbsp;
            {handleSwitchModal ? (
              <Link onClick={() => handleSwitchModal('signup')}>
                สมัครสมาชิก
              </Link>
            ) : (
              <Link as={RouterLink} route="signup">
                สมัครสมาชิก
              </Link>
            )}
          </Text>
        </Box>

        <Box mb="75px">
          <LoginForm
            isLoading={loading}
            errorMsg={errorMsg}
            showResentEmail={showResentEmail}
            resendConfirmation={resendConfirmation}
            onSubmitForm={handleSubmit}
          />

          <Text fontSize="sm">
            จำรหัสผ่านไม่ได้?&nbsp;
            {handleSwitchModal ? (
              <Link onClick={() => handleSwitchModal('forgot')}>
                ขอรหัสผ่านใหม่ที่นี่
              </Link>
            ) : (
              <Link as={RouterLink} route="forgot">
                ขอรหัสผ่านใหม่ที่นี่
              </Link>
            )}
          </Text>
        </Box>

        <Text fontSize="xs">
          <Link as={RouterLink} route="terms">
            ข้อตกลงการใช้บริการ
          </Link>
          &nbsp;และ&nbsp;
          <Link as={RouterLink} route="privacy">
            นโยบายการรักษาข้อมูลส่วนตัว
          </Link>
        </Text>
      </Grid>
    </FormProvider>
  )
}

export default LoginLayout
