import React from 'react'
import { Box, Text, Input, Button, Grid, Link } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { FormControl } from '@/components/FormControl'

import { LoginFormInput } from '../../types'

type LoginFormProps = {
  isLoading: boolean
  onSubmitForm: (data: LoginFormInput) => void
  resendConfirmation: () => Promise<void>
  showResentEmail: boolean
  errorMsg: string
}

const LoginForm = (props: LoginFormProps): React.ReactElement => {
  const {
    showResentEmail,
    resendConfirmation,
    isLoading,
    onSubmitForm,
    errorMsg,
  } = props

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useFormContext<LoginFormInput>()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmitForm(data)
      })}
    >
      <Grid gap="24px" p="55px 80px 0" mb="24px">
        {errorMsg && (
          <Box color="tomato">
            <Text>{errorMsg}</Text>
            {showResentEmail && (
              <Text sx={{ color: 'gray.900', mt: 2 }}>
                คลิก&nbsp;
                <Link onClick={resendConfirmation}>
                  <Text as="strong" textDecor="underline">
                    ที่นี่
                  </Text>
                </Link>
                &nbsp;เพื่อส่งอีเมลยืนยันใหม่อีกครั้ง
              </Text>
            )}
          </Box>
        )}

        <FormControl id="email" errorMsg={errors.email?.message}>
          <Input
            type="email"
            fontSize="sm"
            size="lg"
            placeholder="อีเมลของคุณ"
            {...register('email')}
          />
        </FormControl>
        <FormControl id="password" errorMsg={errors.password?.message}>
          <Input
            type="password"
            fontSize="sm"
            size="lg"
            placeholder="รหัสผ่าน"
            {...register('password')}
          />
        </FormControl>

        <Button
          colorScheme="primary"
          variant="solid"
          size="lg"
          type="submit"
          isLoading={isLoading}
        >
          เข้าสู่ระบบ
        </Button>
      </Grid>
    </form>
  )
}

export default LoginForm
