import { z } from 'zod'

import { passwordValidator } from './components/PasswordInput/schema'

export const passwordAndConfirm = {
  password: z
    .string()
    .nonempty('กรุณากรอกรหัสผ่าน')
    .refine(passwordValidator, 'รหัสผ่านไม่ตรงตามเงื่อนไข'),
  confirmPassword: z.string().nonempty('กรุณากรอกยืนยันรหัสผ่าน'),
}

export const loginFormSchema = z.object({
  email: z.string().nonempty('กรุณากรอกอีเมล').email('อีเมลไม่ถูกต้อง'),
  password: z.string().nonempty('กรุณากรอกรหัสผ่าน'),
})

export const signUpFormSchema = z
  .object({
    email: z.string().nonempty('กรุณากรอกอีเมล').email('อีเมลไม่ถูกต้อง'),
    marketingConsent: z.boolean(),
  })
  .extend(passwordAndConfirm)
  .refine((data) => data.password === data.confirmPassword, {
    message: 'รหัสผ่านไม่ตรงกัน',
    path: ['confirmPassword'], // set path of error
  })

export const changePasswordFormSchema = z
  .object({
    currentPassword: z.string().nonempty('กรุณากรอกรหัสผ่าน'),
  })
  .extend(passwordAndConfirm)
  .refine((data) => data.password === data.confirmPassword, {
    message: 'รหัสผ่านไม่ตรงกัน',
    path: ['confirmPassword'], // set path of error
  })

export const resetPasswordFormSchema = z
  .object({})
  .extend(passwordAndConfirm)
  .refine((data) => data.password === data.confirmPassword, {
    message: 'รหัสผ่านไม่ตรงกัน',
    path: ['confirmPassword'], // set path of error
  })
