import React from 'react'
import {
  Text,
  Input,
  Button,
  FormErrorMessage,
  Grid,
  FormControl,
} from '@chakra-ui/react'

const ForgotForm = (props) => {
  const {
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    errorMsg,
  } = props

  return (
    <form onSubmit={handleSubmit}>
      <Grid gap="24px" p="55px 80px 0" mb="24px">
        {errorMsg && <Text color="tomato">{errorMsg}</Text>}

        <FormControl isInvalid={errors.email && touched.email}>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            fontSize="sm"
            size="lg"
            placeholder="อีเมลของคุณ"
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>

        <Button
          colorScheme="primary"
          variant="solid"
          size="lg"
          type="submit"
          isLoading={isSubmitting}
        >
          ส่งลิงก์สำหรับตั้งรหัสผ่านใหม่
        </Button>
      </Grid>
    </form>
  )
}

export default ForgotForm
