import { FormControl } from '@/components/FormControl'
import { Text, Input, Button, Grid, Checkbox, VStack } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

import { SignUpFormInput } from '../types'
import { PasswordInput } from './PasswordInput'

type SignUpFormProps = {
  errorMsg: string
  isLoading: boolean
  onSubmitForm: (data: SignUpFormInput) => void
}

export const SignUpForm = (props: SignUpFormProps): React.ReactElement => {
  const { errorMsg, onSubmitForm, isLoading } = props

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useFormContext<SignUpFormInput>()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmitForm(data)
      })}
    >
      <Grid gap="24px" p="55px 80px 0" mb="24px">
        {errorMsg && <Text color="tomato">{errorMsg}</Text>}

        <FormControl id="email" errorMsg={errors.email?.message}>
          <Input
            type="email"
            fontSize="sm"
            size="lg"
            placeholder="อีเมล"
            {...register('email')}
          />
        </FormControl>

        <Controller
          control={control}
          name="password"
          render={({ field: { name, onChange, value } }) => {
            return (
              <FormControl id="password" errorMsg={errors.password?.message}>
                <PasswordInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fontSize="sm"
                  size="lg"
                  placeholder="รหัสผ่าน"
                />
              </FormControl>
            )
          }}
        />

        <FormControl
          id="confirm_password"
          errorMsg={errors.confirmPassword?.message}
        >
          <Input
            id="confirm_password"
            fontSize="sm"
            size="lg"
            placeholder="ยืนยันรหัสผ่านอีกครั้ง"
            type="password"
            {...register('confirmPassword')}
          />
        </FormControl>

        <Controller
          control={control}
          name="marketingConsent"
          render={({ field: { value, ...rest } }) => {
            return (
              <FormControl id="marketingConsent">
                <VStack sx={{ fontWeight: 'medium' }}>
                  <Checkbox
                    colorScheme="primary"
                    alignItems="flex-start"
                    size="sm"
                    sx={{ textAlign: 'start' }}
                    isChecked={value}
                    {...rest}
                  >
                    ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA Pro
                    และบริการอื่น ๆ จากเรา บริษัทในเครือ
                    บริษัทย่อยและพันธมิตรทางธุรกิจ &#8203;
                    <span style={{ whiteSpace: 'nowrap' }}>
                      (คุณสามารถยกเลิกได้ทุกเมื่อ)
                    </span>
                  </Checkbox>
                  <Text
                    sx={{
                      fontSize: 'xs',
                      color: 'customGray.700',
                      pl: 5,
                      textAlign: 'left',
                    }}
                  >
                    PDPA Pro
                    อาจติดต่อคุณในบางโอกาสเพื่อให้ข้อมูลที่เป็นประโยชน์และเกี่ยวข้องกับสินค้าและบริการที่คุณเลือกใช้
                  </Text>
                </VStack>
              </FormControl>
            )
          }}
        />

        <Button
          colorScheme="primary"
          variant="solid"
          size="lg"
          type="submit"
          isLoading={isLoading}
        >
          สมัครสมาชิก
        </Button>
      </Grid>
    </form>
  )
}
