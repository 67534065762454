import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  HStack,
} from '@chakra-ui/react'
import { useFormik } from 'formik'

import { CompanyProfileFormInput } from '@/features/user/types'

export type SignUpCompanyProfileFormProps = {
  onSubmit: (data: CompanyProfileFormInput) => void
  onCancel: () => void
  isLoading: boolean
}

const FORM_LABEL_STYLE = {
  color: 'gray.700',
  fontSize: 'sm',
}

export const SignUpCompanyProfileForm = (
  props: SignUpCompanyProfileFormProps,
): React.ReactElement => {
  const { onSubmit, onCancel, isLoading } = props

  const { handleSubmit, handleChange, values } =
    useFormik<CompanyProfileFormInput>({
      onSubmit,
      initialValues: {
        first_name: '',
        last_name: '',
        phone_number: '',
        company_name: '',
      },
    })

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="6">
        <HStack>
          <FormControl id="first-name" isRequired>
            <FormLabel sx={FORM_LABEL_STYLE}>ชื่อ</FormLabel>
            <Input
              name="first_name"
              onChange={handleChange}
              fontSize="sm"
              size="lg"
              placeholder="ชื่อ"
              value={values.first_name}
            />
          </FormControl>
          <FormControl id="last-name" isRequired>
            <FormLabel sx={FORM_LABEL_STYLE}>นามสกุล</FormLabel>
            <Input
              name="last_name"
              onChange={handleChange}
              fontSize="sm"
              size="lg"
              placeholder="นามสกุล"
              value={values.last_name}
            />
          </FormControl>
        </HStack>
        <FormControl id="company_name">
          <FormLabel sx={FORM_LABEL_STYLE}>ชื่อบริษัทหรือชื่อแบรนด์</FormLabel>
          <Input
            name="company_name"
            onChange={handleChange}
            fontSize="sm"
            size="lg"
            placeholder="ชื่อบริษัทหรือชื่อแบรนด์"
            value={values.company_name}
          />
        </FormControl>

        <FormControl id="phone_number" isRequired>
          <FormLabel sx={FORM_LABEL_STYLE}>เบอร์โทรติดต่อ</FormLabel>
          <Input
            name="phone_number"
            onChange={handleChange}
            fontSize="sm"
            size="lg"
            placeholder="เบอร์โทรติดต่อ"
            type="tel"
            inputMode="tel"
            value={values.phone_number}
          />
        </FormControl>

        <Stack spacing="3">
          <Button
            colorScheme="primary"
            size="lg"
            isLoading={isLoading}
            type="submit"
          >
            บันทึก
          </Button>
        </Stack>

        <Text
          sx={{
            color: 'gray.600',
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          คุณสามารถเปลี่ยนรายละเอียดได้ในภายหลัง
        </Text>
      </Stack>
    </form>
  )
}
