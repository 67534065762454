import React, { useState } from 'react'
import { get } from 'lodash'
import { Grid, Box, Heading, Text, Link } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Link as RouterLink } from '@/lib/Link'

import env from '@/config/env'
import * as AuthServices from '@/lib/auth/AuthServices'
import getErrorMsg from '@/lib/helpers/getErrorMsg'
import { acceptConsent } from '@/lib/utils/consent'

import { SuccessSignup } from '../helpers'
import { SignUpFormInput } from '../types'
import { signUpFormSchema } from '../schema'
import { SignUpForm } from './SignUpForm'

type SignUpLayoutProps = {
  handleSwitchModal?: (data: string) => void
}

export const SignUpLayout = (props: SignUpLayoutProps): React.ReactElement => {
  const { handleSwitchModal } = props

  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const { query } = useRouter()

  const handleSubmit = async (values: SignUpFormInput) => {
    const token = get(query, 'token')
    const { email, password, marketingConsent } = values

    setLoading(true)

    try {
      await Promise.all([
        AuthServices.signup({
          email,
          password,
          token,
          marketing_consent: marketingConsent,
        }),

        marketingConsent
          ? acceptConsent({
              apiKey: env.CONSENT_WOW_SIGN_UP_API_KEY,
              purposeId: env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
              data: values,
            })
          : Promise.resolve(null),
      ])

      setSuccess(true)
    } catch (err) {
      const error = getErrorMsg(err)
      setErrorMsg(error)
    } finally {
      setLoading(false)
    }
  }

  const method = useForm<SignUpFormInput>({
    resolver: zodResolver(signUpFormSchema),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      marketingConsent: false,
    },
  })

  if (success) {
    return <SuccessSignup />
  }

  return (
    <FormProvider {...method}>
      <Grid textAlign="center" pb="32px">
        <Box p="36px 0 23px" borderBottom="1px solid #e8e8e8">
          <Heading fontSize="2xl" mb="8px">
            สมัครสมาชิก
          </Heading>
        </Box>

        <Box>
          <SignUpForm
            errorMsg={errorMsg}
            onSubmitForm={handleSubmit}
            isLoading={loading}
          />
        </Box>

        <Text fontSize="xs" mb="75px">
          ในการสมัครใช้บริการคุณได้ยอมรับ&nbsp;
          <Link as={RouterLink} route="terms">
            ข้อตกลงการใช้บริการ
          </Link>
          &nbsp;และ&nbsp;
          <Link as={RouterLink} route="privacy">
            นโยบายการรักษาข้อมูลส่วนตัว
          </Link>
        </Text>

        <Text fontSize="sm">
          มีบัญชีอยู่แล้ว?&nbsp;
          {handleSwitchModal ? (
            <Link onClick={() => handleSwitchModal('login')}>เข้าสู่ระบบ</Link>
          ) : (
            <Link as={RouterLink} route="login">
              เข้าสู่ระบบ
            </Link>
          )}
        </Text>
      </Grid>
    </FormProvider>
  )
}
