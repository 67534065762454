import React from 'react'
import * as Yup from 'yup'
import { Grid, Text, Button } from '@chakra-ui/react'

import { Link } from '@/lib/Link'

export const forgotValidationScheme = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .required('Email should not be empty'),
})

export const SuccessSignup = () => {
  return (
    <Grid p="16px 16px 76px" justifyItems="center">
      <div
        css={{
          padding: '14px',
          marginBottom: '32px',
          backgroundImage: 'linear-gradient(276deg, #00e3ab, #32c4d7)',
          color: 'white',
          display: 'grid',
          gridTemplateColumns: '20px 1fr',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <span
          css={{
            width: '20px',
            height: '18px',
            background:
              'no-repeat center/contain url(/images/auth/new-message-icon.svg)',
          }}
        />
        กรุณาตรวจสอบ ‘กล่องจดหมายเข้า’ ในอีเมลของคุณ เพื่อเสร็จสิ้นการสร้างบัญชี
      </div>
      <div
        css={{
          width: '280px',
          height: '192px',
          background:
            'no-repeat center/contain url(/images/auth/signup-success.svg)',
          margin: '0 auto 20px',
        }}
      />
      <Text mb="56px" fontSize="2xl" textAlign="center">
        บัญชีผู้ใช้งานได้ถูกสร้างเรียบร้อย
      </Text>
      <Link route="login">
        <Button size="lg" w="260px" colorScheme="primary" variant="solid">
          กลับไปหน้า Log in
        </Button>
      </Link>
    </Grid>
  )
}

export const ForgotSuccess = (props) => {
  const { email, handleSwitchModal } = props

  return (
    <Grid justifyItems="center" p="80px 80px 50px" textAlign="center">
      <div
        css={{
          marginBottom: '20px',
          width: '80px',
          height: '80px',
          background:
            'no-repeat center/contain url(/icons/correct-circle-icon.svg)',
        }}
      />
      <Text fontSize="2xl" mb="8px">
        ส่งลิงก์สำหรับการตั้งรหัสใหม่ให้คุณแล้ว
      </Text>
      <Text mb="24px">
        โปรดตรวจสอบอีเมล <strong>{email}</strong>{' '}
        หากคุณมีบัญชีกับเราคุณจะได้รับลิงก์ เพื่อตั้งรหัสผ่านใหม่ของคุณ
      </Text>
      <Text mb="32px">
        หากคุณไม่แน่ใจว่าอีเมลถูกต้องไหม หรือไม่ได้รับอีเมล โปรดติดต่อ
        ฝ่ายบริการลูกค้าของเรา
      </Text>
      {handleSwitchModal ? (
        <Button
          onClick={() => handleSwitchModal('login')}
          w="260px"
          colorScheme="primary"
          variant="solid"
          size="lg"
        >
          กลับหน้าเข้าสู่ระบบ
        </Button>
      ) : (
        <Link route="login">
          <Button w="260px" colorScheme="primary" variant="solid" size="lg">
            กลับหน้าเข้าสู่ระบบ
          </Button>
        </Link>
      )}
    </Grid>
  )
}
