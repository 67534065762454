import React from 'react'
import { Grid, Box, Text } from '@chakra-ui/react'

import { Link } from '@/lib/Link'

const SettingLayout = (props) => {
  const { title, children } = props

  return (
    <Grid
      sx={{
        gridTemplateRows: 'max-content',
        justifyContent: 'center',
        pb: '32px',
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: '24px 24px 1fr',
          alignItems: 'center',
          gap: '8px',
          textAlign: 'start',
          p: '36px 0 23px',
          w: '620px',
        }}
      >
        <Link route="dashboardPolicies">
          <span
            css={{
              width: '24px',
              height: '24px',
              background: 'no-repeat center/contain url(/icons/home-icon.svg)',
              cursor: 'pointer',
            }}
          />
        </Link>
        <span
          css={{
            width: '24px',
            height: '24px',
            background:
              'no-repeat center/contain url(/icons/double-right-icon.svg)',
          }}
        />
        <Text fontSize="2xl">{title}</Text>
      </Grid>

      <Box
        bg="white"
        rounded="4px"
        border="1px solid"
        borderColor="customGray.300"
      >
        {children}
      </Box>
    </Grid>
  )
}

export default SettingLayout
