import { Button, Grid, Input, Text } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormControl } from '@/components/FormControl'
import { PasswordInput } from './PasswordInput'
import { ChangePasswordFormInput } from '../types'

type ChangePasswordFormProps = {
  errorMsg: string
  isLoading: boolean
  onSubmitForm: (data: ChangePasswordFormInput) => void
  email: string
}

export const ChangePasswordForm = (
  props: ChangePasswordFormProps,
): React.ReactElement => {
  const { isLoading, onSubmitForm, errorMsg, email } = props

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useFormContext<ChangePasswordFormInput>()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmitForm(data)
      })}
    >
      <Grid gap="24px" p="32px" w="618px">
        {errorMsg && <Text color="tomato">{errorMsg}</Text>}

        <FormControl label="อีเมล" id="email">
          <Input value={email} isReadOnly isDisabled />
        </FormControl>

        <FormControl
          id="currentPassword"
          label="รหัสปัจจุบันที่ใช้งาน"
          errorMsg={errors.currentPassword?.message}
        >
          <Input
            placeholder="รหัสผ่านปัจจุบัน"
            type="password"
            {...register('currentPassword')}
          />
        </FormControl>

        <Controller
          control={control}
          name="password"
          render={({ field: { name, onChange, value } }) => {
            return (
              <FormControl
                id="password"
                errorMsg={errors.password?.message}
                label="รหัสผ่านใหม่"
              >
                <PasswordInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fontSize="sm"
                  size="lg"
                  placeholder="รหัสผ่านใหม่"
                />
              </FormControl>
            )
          }}
        />

        <FormControl
          id="confirmPassword"
          errorMsg={errors.confirmPassword?.message}
          label="ยืนยันรหัสผ่านอีกครั้ง"
        >
          <Input
            id="confirmPassword"
            fontSize="sm"
            size="lg"
            placeholder="ยืนยันรหัสผ่านอีกครั้ง"
            type="password"
            {...register('confirmPassword')}
          />
        </FormControl>

        <Button
          colorScheme="primary"
          variant="solid"
          size="lg"
          type="submit"
          fontSize="md"
          isLoading={isLoading}
        >
          บันทึก
        </Button>
      </Grid>
    </form>
  )
}
