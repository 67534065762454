import { ReactNode } from 'react'
import {
  Collapse,
  FormControl as ChakraFC,
  FormErrorMessage,
  FormLabel,
  SystemStyleObject,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

type Props = {
  children: ReactNode
  errorMsg?: string | FieldError
  id: string
  isRequired?: boolean
  label?: string
  sx?: SystemStyleObject
}

export const FormControl = (props: Props) => {
  const { label, children, errorMsg, id, sx, isRequired = false } = props

  return (
    <ChakraFC
      id={id}
      isInvalid={Boolean(errorMsg)}
      isRequired={isRequired}
      sx={sx}
    >
      {label && <FormLabel>{label}</FormLabel>}
      {children}

      <Collapse in={Boolean(errorMsg)} animateOpacity>
        <FormErrorMessage data-testid="error-msg">{errorMsg}</FormErrorMessage>
      </Collapse>
    </ChakraFC>
  )
}
